import i18n from "@/plugins/i18n"
import useCatchHandler from "@/services/catchErrorHandler"
import useOAuth from "@/services/oauth"
import { useStore } from "@/store"
import { ActionTypes as StatActions } from "@/store/modules/global/actions"
import axios from "axios"
import JSZip from "jszip"
import { computed } from "vue"
import { useRoute } from "vue-router"
import { toast } from "vue3-toastify"

declare const saveAs: any

export default function useApiAssessment() {
    const { handleError } = useCatchHandler()
    const { API_PREFIX } = useOAuth()
    const route = useRoute()
    const zip = new JSZip()

    const store = useStore()
    const getApiRequestHeader = computed(
        () => store.getters.getApiRequestHeader
    )

    const apiGetAssessmentLimit = async () => {
        try {
            return await axios.get(
                `${API_PREFIX}/assessment/create/assessment/limit`,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }

    const apiGetCompanyTestLimit = async () => {
        try {
            return await axios.get(
                `${API_PREFIX}/test/library/company/test/limit`,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }

    const apiCreateAssessment = async (
        title: string,
        jobRoleId: string,
        otherJobRole: string,
        cloneTemplate: boolean,
        customQuestionTimerType: string,
        customQuestionTime: number,
        assessmentDescription: string,
        defaultLanguage: string = "en"
    ) => {
        try {
            const body = {
                title,
                jobRoleId,
                otherJobRole,
                cloneTemplate,
                customQuestionTimerType,
                customQuestionTime,
                defaultLanguage,
                assessmentDescription,
            }
            return await axios.post(
                `${API_PREFIX}/assessment`,
                body,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            return response.data
        }
    }

    const apiAssessmentAnalytics = async (id: string, candidateId: string) => {
        try {
            if (route.query.hasOwnProperty("auth")) {
                const auth = route.query["auth"]
                let response: any = await axios.get(
                    `${API_PREFIX}/public/assessment/analytics?auth=${auth}`
                )
                return response
            } else {
                let url = `${API_PREFIX}/assessment/${id}/analytics`
                if (candidateId) {
                    url += `?candidateId=${candidateId}`
                }
                let response: any = await axios.get(
                    url,
                    getApiRequestHeader.value
                )
                return response
            }
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }
    const apiAiAssessmentStatus = async (id: string) => {
        try {
            return await axios.get(
                `${API_PREFIX}/assessment/${id}/ai/status`,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }

    const apiCreateAssessmentGenerativeAI = async (
        title: string,
        jobRoleId: string,
        skills: string[],
        language: string,
        overview: string,
        experience: string,
        numberOfQuestions: number,
        assessmentId: "",
        regenerate: boolean,
        aiGenerationMetadata: any,
        questionTypes: any
    ) => {
        try {
            const body = {
                title,
                jobRoleId,
                numberOfQuestions,
                skills,
                language,
                experience,
                assessmentId,
                regenerate,
                aiGenerationMetadata,
                questionTypes,
                ...(overview !== "" && { overview }),
            }
            return await axios.post(
                `${API_PREFIX}/assessment/questions/ai/generate`,
                body,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            return response.data
        }
    }

    const apiEditAssessment = async (
        id: string,
        title: string,
        jobRoleId: string,
        otherJobRole: string,
        customQuestionTimerType: string,
        customQuestionTime: number,
        libraries: object,
        customQuestions: object,
        assessmentDescription: string
    ) => {
        try {
            const body = {
                title,
                jobRoleId,
                otherJobRole,
                customQuestionTimerType,
                customQuestionTime,
                assessmentDescription,
                libraries,
                customQuestions,
            }
            return await axios.patch(
                `${API_PREFIX}/assessment/${id}`,
                body,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }

    const apiDeleteAssessment = async (id: string) => {
        try {
            return await axios.delete(
                `${API_PREFIX}/assessment/${id}`,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }

    const apiSessionRecordings = async (
        candidateEmails: string,
        assessmentIds: string,
        candidateIds: string
    ) => {
        const query = `?candidateEmails=${encodeURIComponent(
            candidateEmails
        )}&assessmentIds=${assessmentIds}&candidateIds=${candidateIds}`
        try {
            const endpoint = `${API_PREFIX}/assessment/recordings${query}`
            const { data } = await axios.get(
                endpoint,
                getApiRequestHeader.value
            )
            return data
        } catch ({ response }) {
            const errors: any = response!.data!.error
            const { statusCode } = errors
            if (statusCode === 400) {
                console.log("statusCode", statusCode)
                return statusCode
            } else {
                handleError(response)
            }
            return -1
        }
    }

    const apiCloneAssessment = async (
        assessmentTitle: string,
        assessmentId: string,
        selectedWorkspaceList: string[]
    ) => {
        try {
            const body = {
                assessmentTitle,
                assessmentId,
                orgIds: selectedWorkspaceList,
            }
            return await axios.post(
                `${API_PREFIX}/assessment/clone`,
                body,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }
    const apiCloneCompanyTest = async (
        testLibraryTitle: string,
        testLibraryId: string
    ) => {
        try {
            const body = {
                testLibraryTitle,
                testLibraryId,
            }
            return await axios.post(
                `${API_PREFIX}/test/library/clone`,
                body,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }
    const apiEditArchive = async (
        id: string,
        title: string,
        isArchived: boolean,
        jobRoleId: string
    ) => {
        try {
            const body = { title, isArchived, jobRoleId }
            return await axios.patch(
                `${API_PREFIX}/assessment/${id}`,
                body,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }
    const apiArchiveAssessment = async (
        assessmentIds: string[],
        unarchive: boolean
    ) => {
        try {
            const body = { assessmentIds, unarchive }
            return await axios.patch(
                `${API_PREFIX}/assessment/archive`,
                body,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }

    const apiGetTestLangugesList = async () => {
        try {
            return await axios.get(
                `${API_PREFIX}/language`,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }

    const apiGetJobRoleList = async (query: string) => {
        try {
            return await axios.get(
                `${API_PREFIX}/workspace/jobrole?q=${query}`,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }

    const apiFetchSkillsGenerativeAI = async (
        jobRoleId: string,
        skillCount: number = 10,
        query: string = ""
    ) => {
        try {
            return await axios.get(
                `${API_PREFIX}/workspace/jobrole/${jobRoleId}/${skillCount}?q=${query}`,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }

    const apiGetTestList = async (
        limit: number,
        skip: number,
        query: string,
        type: string[],
        difficulty: string[],
        jobRoleId: string = "",
        sort: string,
        isCompanyTest: boolean,
        status: string,
        codingLanguage: string[],
        isTestlifyLibraries: boolean,
        language: string[],
        isAssessment: boolean,
        archived: boolean,
        industryType: string[],
        template: boolean = false
    ) => {
        try {
            return await axios.get(
                `${API_PREFIX}/test/library?limit=${limit}&skip=${skip}&query=${encodeURIComponent(
                    query
                )}&template=${template}&type=${type}&difficulty=${difficulty}&jobRoleId=${jobRoleId}&sort=${sort}&isCompanyTest=${isCompanyTest}&status=${status}&codingLanguage=${codingLanguage}&isTestlifyLibraries=${isTestlifyLibraries}&language=${language}&isAssessment=${isAssessment}&archived=${archived}&industryType=${industryType}`,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }
    const apiGetTypeList = async () => {
        try {
            return await axios.get(
                `${API_PREFIX}/static/testlibrary/type`,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }
    const apiGetIndustryTypes = async () => {
        try {
            return await axios.get(
                `${API_PREFIX}/static/testlibrary/industry/types`,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }
    const apiGetSkillTypes = async (query: string) => {
        try {
            return await axios.get(
                `${API_PREFIX}/candidate/skills?skip=0&limit=50&query=${query}`,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }
    const apiGetLocationTypes = async (query: string) => {
        try {
            return await axios.get(
                `${API_PREFIX}/static/master/location?limit=50&skip=0&query=${query}`,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }

    const apiUpdateAssessment = async (id: string, body: object) => {
        try {
            return await axios.put(
                `${API_PREFIX}/assessment/${id}`,
                body,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }
    const apiUpdateTestLibrary = async (id: string, body: object) => {
        try {
            return await axios.put(
                `${API_PREFIX}/assessment/${id}/test-library`,
                body,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }
    const apiUpdateAssessmentSettings = async (id: string, body: object) => {
        try {
            return await axios.put(
                `${API_PREFIX}/assessment/${id}/setting`,
                body,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }

    const apiGetAssessmentList = async (
        limit: number,
        skip: number,
        query: string,
        colName: string,
        inOrder: string,
        isArchived: boolean,
        isActive: boolean,
        isDraft: boolean,
        isEditable: boolean
    ) => {
        try {
            return await axios.get(
                `${API_PREFIX}/assessment?limit=${limit}&skip=${skip}&query=${encodeURIComponent(
                    query
                )}&colName=${colName}&inOrder=${inOrder}&isArchived=${isArchived}&isEditable=${isEditable}&isActive=${isActive}&isDraft=${isDraft}`,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }
    const apiGetAssessmentFeedback = async (
        ratings: number,
        review: string,
        assessmentId: string
    ) => {
        try {
            const body = {
                ratings,
                review,
                assessmentId,
            }
            return await axios.post(
                `${API_PREFIX}/assessment/feedback`,
                body,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }
    const apiInviteAssessmentCandidate = async (
        candidateInvites: Array<object>,
        assessmentId: string
    ) => {
        try {
            const body = {
                candidateInvites,
                assessmentId,
            }
            return await axios.post(
                `${API_PREFIX}/assessment/candidate/invites`,
                body,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }
    const apiInviteInterviewCandidate = async (
        candidateId: string,
        candidateGroupId: string,
        orgId: string,
        jobRole: string,
        email: string,
        resellerId: string,
        userId: string
    ) => {
        try {
            const body = {
                candidateId,
                candidateGroupId,
                orgId,
                jobRole,
                email,
                resellerId,
                userId,
            }
            console.log(body)
            return await axios.post(
                `${API_PREFIX}/interview/candidate/invite`,
                body,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }
    const apiGetTalentList = async (
        limit: number,
        skip: number,
        skill: string,
        industry: string[],
        location: string[],
        query: string,
        jobRoleId: string
    ) => {
        try {
            return await axios.get(
                `${API_PREFIX}/candidate/profile/list?limit=${limit}&skip=${skip}&skills=${skill}&industry=${industry}&query=${query}&location=${location}&jobRoleId=${jobRoleId}`,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }
    const apiGetAssessmentListForInterview = async (candidateId: string) => {
        try {
            return await axios.get(
                `${API_PREFIX}/assessment/candidate/${candidateId}`,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }
    const apiReinviteAssessmentCandidate = async (
        candidateInvites: Array<object>,
        assessmentId: string
    ) => {
        try {
            const body = {
                candidateInvites,
                assessmentId,
            }
            return await axios.post(
                `${API_PREFIX}/assessment/candidate/resend/invite`,
                body,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }

    const apiGetAssessmentTestDetails = async (id: string) => {
        try {
            return await axios.get(
                `${API_PREFIX}/assessment/${id}`,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }
    const apiGetAssessmentCandidateDetails = async (
        id: string,
        limit: number,
        skip: number,
        query: string,
        candidateStatus: string,
        candidateStage: string,
        grade: string,
        colName: string,
        inOrder: string,
        range: string,
        workspaceLabelTitle: Array<object>,
        invitedBy: string
    ) => {
        try {
            return await axios.get(
                `${API_PREFIX}/assessment/${id}/candidate?limit=${limit}&skip=${skip}&query=${encodeURIComponent(
                    query
                )}&candidateStatus=${candidateStatus}&candidateStage=${candidateStage}&grade=${grade}&colName=${colName}&inOrder=${inOrder}&completedRange=${range}&workspaceLabelTitle=${workspaceLabelTitle}&invitedBy=${invitedBy}`,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }
    const apiGetAssessmentLists = async (id: string) => {
        try {
            return await axios.get(
                `${API_PREFIX}/assessment/${id}/assessments`,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }
    const apiDownloadFile = async (
        id: string,
        fileType: string,
        candidateIds: string[] = []
    ) => {
        try {
            const body = {
                candidateIds: candidateIds,
            }
            await axios.post(
                `${API_PREFIX}/assessment/${id}/candidate/file/download/${fileType}/async`,
                body,
                {
                    headers: {
                        "Content-Type":
                            fileType === "csv"
                                ? "text/csv"
                                : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    },
                    ...getApiRequestHeader.value,
                }
            )
        } catch ({ response }) {
            handleError(response)
            return
        }
    }

    const apiDownloadPdfFile = async (
        assessmentId: string,
        candidateId: string,
        report: string = "",
        testLibId: string = "",
        type: string = ""
    ) => {
        try {
            return await axios
                .get(
                    `${API_PREFIX}/assessment/${assessmentId}/candidate/${candidateId}?fileType=pdf&report=${report}&testLibId=${testLibId}`,
                    getApiRequestHeader.value
                )
                .then((response) => {
                    let fileArr = []
                    const fileLink = response.data.pdfUrl
                    const cefrLink = response.data.cefrCertificatePdfUrl
                    const psychometricTestLink =
                        response.data.psychometricTestPdf
                    const discLink = response.data.discTestPdf
                    const culturalFitLink = response.data.culturalFitTestPdf
                    const customerServiceAdvisorLink =
                        response.data.customerServiceAdvisorTestPdf
                    const conflictManagementLink =
                        response.data.conflictManagementSkillsTestPdf
                    const workEtiquetteLink = response.data.workEtiquetteTestPdf
                    const generalWorkforceLink =
                        response.data.generalWorkforceTestPdf
                    const enneagramLink = response.data.enneagramTestPdf
                    let timestamp = new Date().getTime()
                    let SJTResults = response.data.commonSJTReports
                        ? response.data.commonSJTReports
                        : []

                    let firstName = response.data?.firstName
                    let lastName = response.data.lastName
                    let assessmentTitle = response.data.title

                    if (report === "" || type == "sjt") {
                        if (SJTResults.length > 0 && SJTResults !== undefined) {
                            SJTResults?.forEach((item) => {
                                fileArr.push({
                                    name:
                                        `${firstName}  ${lastName} - ${item.title}` +
                                        ".pdf",
                                    url: item.reportUrl,
                                })
                            })
                        }
                    }

                    if (testLibId !== "") {
                        const testlib = response.data.testLibrary.find(
                            (item) => item.testLibraryId === testLibId
                        )
                        fileArr.push({
                            name:
                                response.data.firstName +
                                " " +
                                response.data.lastName +
                                " - " +
                                assessmentTitle +
                                ".pdf",
                            url: testlib?.pdfReportUrl,
                        })
                    }
                    if (
                        report === "cefr" ||
                        (report === "" && cefrLink && type !== "sjt")
                    ) {
                        fileArr.push({
                            name:
                                response.data.firstName +
                                " " +
                                response.data.lastName +
                                " - " +
                                assessmentTitle +
                                ".pdf",
                            url: cefrLink,
                        })
                    }
                    if (
                        report === "assessmentReport" ||
                        (report === "" && fileLink && type != "sjt")
                    ) {
                        fileArr.push({
                            name:
                                response.data.firstName +
                                " " +
                                response.data.lastName +
                                " - " +
                                assessmentTitle +
                                ".pdf",
                            url: fileLink,
                        })
                    }
                    if (
                        report === "smart" ||
                        (report === "" && psychometricTestLink && type != "sjt")
                    ) {
                        fileArr.push({
                            name:
                                response.data.firstName +
                                " " +
                                response.data.lastName +
                                " - " +
                                assessmentTitle +
                                ".pdf",
                            url: psychometricTestLink,
                        })
                    }
                    if (
                        report === "disc" ||
                        (report === "" && discLink && type != "sjt")
                    ) {
                        fileArr.push({
                            name:
                                response.data.firstName +
                                " " +
                                response.data.lastName +
                                " - " +
                                assessmentTitle +
                                ".pdf",
                            url: discLink,
                        })
                    }
                    if (
                        report === "culturalFit" ||
                        (report === "" && culturalFitLink && type != "sjt")
                    ) {
                        fileArr.push({
                            name:
                                response.data.firstName +
                                " " +
                                response.data.lastName +
                                " - " +
                                assessmentTitle +
                                ".pdf",
                            url: culturalFitLink,
                        })
                    }
                    if (
                        report === "csa" ||
                        (report === "" &&
                            customerServiceAdvisorLink &&
                            type != "sjt")
                    ) {
                        fileArr.push({
                            name:
                                response.data.firstName +
                                " " +
                                response.data.lastName +
                                " - " +
                                assessmentTitle +
                                ".pdf",
                            url: customerServiceAdvisorLink,
                        })
                    }
                    if (
                        report === "cms" ||
                        (report === "" &&
                            conflictManagementLink &&
                            type != "sjt")
                    ) {
                        fileArr.push({
                            name:
                                response.data.firstName +
                                " " +
                                response.data.lastName +
                                " - " +
                                assessmentTitle +
                                ".pdf",
                            url: conflictManagementLink,
                        })
                    }
                    if (
                        report === "wet" ||
                        (report === "" && workEtiquetteLink && type != "sjt")
                    ) {
                        fileArr.push({
                            name:
                                response.data.firstName +
                                " " +
                                response.data.lastName +
                                " - " +
                                assessmentTitle +
                                ".pdf",
                            url: workEtiquetteLink,
                        })
                    }
                    if (
                        report === "gwt" ||
                        (report === "" && generalWorkforceLink && type != "sjt")
                    ) {
                        fileArr.push({
                            name:
                                response.data.firstName +
                                " " +
                                response.data.lastName +
                                " - " +
                                assessmentTitle +
                                ".pdf",
                            url: generalWorkforceLink,
                        })
                    }
                    if (
                        report === "enneagram" ||
                        (report === "" && enneagramLink && type != "sjt")
                    ) {
                        fileArr.push({
                            name:
                                response.data.firstName +
                                " " +
                                response.data.lastName +
                                " - " +
                                assessmentTitle +
                                ".pdf",
                            url: enneagramLink,
                        })
                    }
                    if (report === "") {
                        const validFileArr = fileArr.filter(
                            (item) => item.url !== "null"
                        )
                        downloadAll(validFileArr, response.data)
                    } else {
                        downloadPdf(
                            fileArr[0].url + "?v=" + timestamp,
                            fileArr[0].name
                        )
                    }
                    return 1
                })
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }

    const downloadPdf = async (link: string, pdfName: string) => {
        try {
            const newHostname = "testlify-eu.s3.amazonaws.com"
            const updatedUrl = link
                .replace("d5l8zhw44l206.cloudfront.net", newHostname)
                .replace("cdn-global.net", newHostname)
            const response = await axios.get(updatedUrl, {
                responseType: "arraybuffer",
            })
            const data = new Uint8Array(response.data)

            const blob = new Blob([data], { type: "application/pdf" })
            const url = URL.createObjectURL(blob)

            const a = document.createElement("a")
            a.href = url
            a.download = pdfName

            // Trigger the click event
            const clickEvent = new MouseEvent("click", {
                view: window,
                bubbles: true,
                cancelable: false,
            })
            a.dispatchEvent(clickEvent)

            URL.revokeObjectURL(url)
        } catch (error) {
            console.error("Error downloading PDF:", error)
        }
    }

    const download = async (item) => {
        const newHostname = "testlify-eu.s3.amazonaws.com"
        const updatedUrl = item.url
            .replace("d5l8zhw44l206.cloudfront.net", newHostname)
            .replace("cdn-global.net", newHostname)
        let timestamp = new Date().getTime() // To get the timestamp
        //download single file as blob and add it to zip archive
        const resp = await axios.get(updatedUrl + "?v=" + timestamp, {
            responseType: "blob",
        })
        zip.file(item.name, resp.data)
    }

    const downloadAll = async (fileArr: any[], data: any) => {
        const arrOfFiles = fileArr.map((item: any) => download(item)) //create array of promises
        Promise.all(arrOfFiles)
            .then(() => {
                //when all promises resolved - save zip file
                zip.generateAsync({ type: "blob" }).then(function (blob) {
                    saveAs(
                        blob,
                        data.firstName +
                            " " +
                            data.lastName +
                            " - " +
                            data.assessmentName +
                            ".zip"
                    )
                })
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const apiSendResult = async (assessmentId: string, candidateId: string) => {
        try {
            return await axios.get(
                `${API_PREFIX}/assessment/${assessmentId}/candidate/${candidateId}/result/send`,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }
    const apiDeleteBulkCandidate = async (
        assessmentId: string,
        candidateIds: any,
        isAllSelect: boolean
    ) => {
        try {
            const apiConfig = getApiRequestHeader.value
            const url = `${API_PREFIX}/assessment/${assessmentId}/candidate/bulk`
            const data = {
                candidateIds,
                isAllSelect,
            }
            return await axios.delete(url, { ...apiConfig, data })
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }
    const apiDeleteCustomQuestion = async (
        assessmentId: string,
        id: string
    ) => {
        try {
            return await axios.delete(
                `${API_PREFIX}/assessment/custom/question/${assessmentId}/${id}`,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }

    const apiBulkInviteCandidates = async (
        assessmentId: string,
        csvFile: string
    ) => {
        try {
            const headers = {
                Authorization: getApiRequestHeader.value.headers.Authorization,
                "Content-Type": "multipart/form-data",
            }
            const formData = new FormData()
            formData.append("file", csvFile)
            return await axios.post(
                `${API_PREFIX}/assessment/${assessmentId}/candidate/bulk/invite/async`,
                formData,
                { headers }
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }

    const apiJoinWaitlist = async (libraryId: string) => {
        try {
            const body = {
                id: libraryId,
            }
            return await axios.post(
                `${API_PREFIX}/test/library/${libraryId}/waitlist`,
                body,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }
    const apiGenerateCandidateInvitationLink = async (
        assessmentId: string,
        email: string
    ) => {
        try {
            const body = { email }
            return await axios.post(
                `${API_PREFIX}/assessment/${assessmentId}/candidate/link`,
                body,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            return response.data
        }
    }
    const apiGetCodingLanguages = async (query: string = "") => {
        try {
            return await axios.get(
                `${API_PREFIX}/assessment/coding/language?query=${query}`,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }
    const apiGetCodingLanguageSnippetCode = async (
        codingLanguageId: string
    ) => {
        try {
            return await axios.get(
                `${API_PREFIX}/assessment/candidate/coding/question/snippet/${codingLanguageId}`,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            return response.data
        }
    }
    const apiGetLanguages = async (userType) => {
        try {
            const response = await axios.get(
                `${API_PREFIX}/public/language/userType/${userType}`
            )
            if (response.data) {
                store.dispatch(StatActions.SET_LANGUAGE_LIST, response.data)
            }
            return response
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }
    const apiCreateCodingQuestion = async (
        sourceId: string,
        title: string,
        timeLimit: number,
        difficultyLevel: string,
        question: string,
        codingLanguages: Array<object>,
        testCases: Array<object>,
        maximumScore: number
    ) => {
        try {
            const body = {
                sourceId,
                title,
                timeLimit,
                difficultyLevel,
                question,
                codingLanguages,
                testCases,
                maximumScore,
            }
            return await axios.post(
                `${API_PREFIX}/assessment/custom/question/coding/question`,
                body,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }
    const apiGetCodingQuestion = async (testLibraryId: string) => {
        try {
            return await axios.get(
                `${API_PREFIX}/assessment/custom/question/coding/question/${testLibraryId}`,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }
    const apiUpdateCodingQuestion = async (
        testLibraryId: string,
        questionId: string,
        title: string,
        timeLimit: number,
        question: string,
        testCases: Array<object>,
        codingLanguages: Array<object>,
        difficultyLevel: string,
        maximumScore: number
    ) => {
        try {
            const body = {
                testLibraryId,
                questionId,
                title,
                timeLimit,
                question,
                testCases,
                codingLanguages,
                difficultyLevel,
                maximumScore,
            }
            return await axios.put(
                `${API_PREFIX}/assessment/custom/question/coding/question`,
                body,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }

    const apiAssessmentQuestionSetScore = async (
        assessmentId: string,
        candidateId: string,
        questionId: string,
        score: number
    ) => {
        try {
            const body = {
                assessmentId,
                candidateId,
                questionId,
                score,
            }
            const response = await axios.put(
                `${API_PREFIX}/assessment/custom/question/score`,
                body,
                getApiRequestHeader.value
            )
            if (response.status === 204)
                toast(
                    `${i18n.global.t(
                        "custom_que.toast_notifications.score_updated"
                    )}`,
                    {
                        type: "success",
                        autoClose: 4000,
                        position: "bottom-left",
                    }
                )
            return response
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }
    const apiAssessmentTestQuestionSetScore = async (
        assessmentId: string,
        candidateId: string,
        questionId: string,
        score: number
    ) => {
        try {
            const body = {
                assessmentId,
                candidateId,
                questionId,
                score,
            }
            const response = await axios.put(
                `${API_PREFIX}/assessment/question/score`,
                body,
                getApiRequestHeader.value
            )
            if (response.status === 204)
                toast(
                    `${i18n.global.t(
                        "custom_que.toast_notifications.score_updated"
                    )}`,
                    {
                        type: "success",
                        autoClose: 4000,
                        position: "bottom-left",
                    }
                )
            return response
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }
    const getTestLibrariesId = async (lang) => {
        try {
            return await axios.get(
                `${API_PREFIX}/test/library/filter?language=${lang}`,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }

    const apiVerifyDocumentLink = async (docLink: string, docType: string) => {
        try {
            return await axios.get(
                `${API_PREFIX}/assessment/custom/question/document/verify/link?docLink=${docLink}&docType=${docType}`,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            toast(
                `${i18n.global.t("add_test_case_modal.necessary_permission")}`,
                {
                    type: "error",
                    autoClose: 4000,
                    position: "bottom-left",
                }
            )
            return -1
        }
    }

    const apiGetAssessmentStatusCount = async (assessmentId: string) => {
        try {
            return await axios.get(
                `${API_PREFIX}/assessment/${assessmentId}/candidate/status`,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }

    const apiGetQuestionList = async (
        limit: number,
        skip: number,
        query: string,
        testLibraryId: string[],
        type: string[],
        difficulty: string[],
        isCompanyTest: boolean,
        isTestlifyLibraries: boolean,
        language: string[],
        isStarred: boolean,
        isCompany: boolean = false
    ) => {
        try {
            return await axios.get(
                `${API_PREFIX}/question?limit=${limit}&skip=${skip}&query=${encodeURIComponent(
                    query
                )}&type=${type}&difficulty=${difficulty}&testLibraryId=${testLibraryId}&isCompanyTestLibraries=${isCompanyTest}&isTestlifyLibraries=${isTestlifyLibraries}&language=${language}&isStarred=${isStarred}&isCompanyTest=${isCompany}`,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }

    const apiGetNotificationList = async (
        assessmentId: string,
        limit: number,
        skip: number
    ) => {
        try {
            return await axios.get(
                `${API_PREFIX}/assessment/notifications?assessmentId=${assessmentId}&limit=${limit}&skip=${skip}`,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }
    const apiGetNotificationAssessmentList = async () => {
        try {
            return await axios.get(
                `${API_PREFIX}/assessment/filter/list`,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }

    const apiMarkAllReadNotifications = async (notificationIds: string[]) => {
        try {
            const body = { notificationIds: [notificationIds] }
            return await axios.patch(
                `${API_PREFIX}/assessment/notifications/read`,
                body,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }

    const apiStarMarkQuestion = async (questionId: string) => {
        try {
            const body = { questionId }
            return await axios.post(
                `${API_PREFIX}/question/starred/${questionId}`,
                body,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }

    const apiUnStarQuestion = async (questionId: string) => {
        try {
            return await axios.delete(
                `${API_PREFIX}/question/starred/${questionId}`,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }

    const apiGetQuestion = async (questionId: string) => {
        try {
            return await axios.get(
                `${API_PREFIX}/question/${questionId}`,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }

    const apiGetTestQuestions = async (
        assessmentId: string,
        candidateId: string,
        libraryId: string
    ) => {
        try {
            if (route.query.hasOwnProperty("auth")) {
                const auth = route.query["auth"]
                // use this url when shareable link is opened
                let response: any = await axios.get(
                    `${API_PREFIX}/public/assessment/candidate/library/${libraryId}?auth=${auth}`
                )
                return response
            } else {
                let response: any = await axios.get(
                    `${API_PREFIX}/assessment/${assessmentId}/candidate/${candidateId}/library/${libraryId}`,
                    getApiRequestHeader.value
                )
                return response
            }
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }

    const apiGetAnalyticQuestionList = async () => {
        try {
            const fromDate = new Date(
                new Date().getTime() - 3600 * 1000 * 24 * 30
            ).toLocaleDateString("en-CA")
            const toDate = new Date().toLocaleDateString("en-CA")
            return await axios.get(
                `${API_PREFIX}/analytics/questions?from_date=${fromDate}&to_date=${toDate}`,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }

    const apiGetAnalyticQuestionEmbed = async (
        questionId: string = "",
        assessmentId: string = "",
        fromDate: string = "",
        toDate: string = ""
    ) => {
        try {
            return await axios.get(
                `${API_PREFIX}/analytics/question/embed?questionId=${questionId}&assessmentId=${
                    assessmentId ?? ""
                }&from_date=${fromDate}&to_date=${toDate} `,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }

    const apiCorrectionRequest = async (
        assessmentId: any,
        candidateId: any,
        questionId: string,
        questionType: string,
        options: any[],
        comment: string
    ) => {
        try {
            let body

            if (questionId && questionType) {
                // If question is loaded then current question ID and Type will be sent
                // If question is not loaded then previous question ID and Type will be sent
                body = {
                    questionId,
                    questionType,
                    options,
                    comment,
                    isFirstQuestion: false,
                }
            } else {
                //If first question is not loaded
                body = {
                    options,
                    comment,
                    isFirstQuestion: false,
                }
            }

            const response: any = await axios.post(
                `${API_PREFIX}/assessment/${assessmentId}/candidate/${candidateId}/question/feedback`,
                body,
                getApiRequestHeader.value
            )
            return response.data
        } catch ({ response }) {
            handleError(response?.data?.error)
            return -1
        }
    }
    const apiCopyAsCompanyTest = async (assessmentId: string) => {
        try {
            let body = {}
            return await axios.post(
                `${API_PREFIX}/assessment/${assessmentId}/questions/test`,
                body,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }

    return {
        apiAiAssessmentStatus,
        apiGetNotificationList,
        apiGetNotificationAssessmentList,
        apiMarkAllReadNotifications,
        apiGetJobRoleList,
        apiFetchSkillsGenerativeAI,
        apiGetTestList,
        apiCreateAssessment,
        apiGetAssessmentLimit,
        apiAssessmentAnalytics,
        apiGetCompanyTestLimit,
        apiCreateAssessmentGenerativeAI,
        apiEditAssessment,
        apiGetTypeList,
        apiGetIndustryTypes,
        apiUpdateAssessment,
        apiGetAssessmentList,
        apiGetAssessmentFeedback,
        apiInviteAssessmentCandidate,
        apiInviteInterviewCandidate,
        apiGetTalentList,
        apiGetAssessmentListForInterview,
        apiReinviteAssessmentCandidate,
        apiGetAssessmentTestDetails,
        apiGetAssessmentCandidateDetails,
        apiDeleteCustomQuestion,
        apiBulkInviteCandidates,
        apiJoinWaitlist,
        apiDownloadFile,
        apiDownloadPdfFile,
        apiGenerateCandidateInvitationLink,
        apiEditArchive,
        apiArchiveAssessment,
        apiDeleteAssessment,
        apiSendResult,
        apiDeleteBulkCandidate,
        apiGetCodingLanguages,
        apiGetCodingLanguageSnippetCode,
        apiCloneAssessment,
        apiCloneCompanyTest,
        apiGetLanguages,
        apiUpdateCodingQuestion,
        apiGetCodingQuestion,
        apiCreateCodingQuestion,
        apiSessionRecordings,
        apiUpdateAssessmentSettings,
        apiAssessmentQuestionSetScore,
        apiAssessmentTestQuestionSetScore,
        apiVerifyDocumentLink,
        apiGetTestLangugesList,
        apiGetAssessmentLists,
        apiGetQuestionList,
        apiGetAssessmentStatusCount,
        getTestLibrariesId,
        apiStarMarkQuestion,
        apiUnStarQuestion,
        apiGetQuestion,
        apiUpdateTestLibrary,
        apiGetTestQuestions,
        apiGetAnalyticQuestionList,
        apiGetAnalyticQuestionEmbed,
        apiGetSkillTypes,
        apiGetLocationTypes,
        apiCorrectionRequest,
        apiCopyAsCompanyTest,
    }
}
