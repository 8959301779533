import { useStore } from "@/store"

const store = useStore()
const bufferTimeout = 1000
const rootLevelHost = location.host
    .split(".")
    .reverse()
    .slice(0, 2)
    .reverse()
    .join(".")
    .split(":")[0]
const domain = location.host.split(".").reverse()[0]
let lastEventName = ""
let buffer = null

function generatePayload(
    eventName: string,
    properties: { [key: string]: any }
) {
    const payload = {
        eventName: eventName,
        sessionId: store.getters.getSessionId,
        properties: properties,
    }
    if (
        store.getters.getAuthUser.sessionAccessKey &&
        store.getters.getAuthUser.sessionAccessKey.length > 5
    ) {
        payload["userId"] = store.getters.getAuthUser.id
        payload.properties["workspaceUrl"] = store.getters.getWorkspaceUrl
        payload.properties["workspaceId"] = store.getters.getWorkspaceId
    }
    return payload
}

async function sendPayload(payload: { [key: string]: any }) {
    if (
        rootLevelHost !== "vercel.app" &&
        rootLevelHost !== "localhost" &&
        domain !== "local"
    ) {
        await fetch("https://events.testlify.com/api/event", {
            method: "POST",
            mode: "no-cors",
            cache: "no-cache",
            referrerPolicy: "no-referrer",
            body: JSON.stringify(payload),
        })
    } else {
        console.log("collectEvent", payload)
    }
}

export async function immediateCollectEvent(
    eventName: string,
    properties: { [key: string]: any }
) {
    const payload = generatePayload(eventName, properties)
    await sendPayload(payload)
}

export default function collectEvent(
    eventName: string,
    properties: { [key: string]: any }
) {
    const payload = generatePayload(eventName, properties)

    // make sure only unique events goes within set interval to protect against burst of events
    // it will only discard same event coming back to back within set interval and keep the latest entry
    if (buffer && eventName === lastEventName) {
        window.clearTimeout(buffer)
    }
    lastEventName = eventName
    buffer = window.setTimeout(function () {
        sendPayload(payload)
    }, bufferTimeout)
}
