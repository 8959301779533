import {
    assessmentConfig,
    TypeAssessmentDetails,
    TypeCustomQuestionCart,
    TypeGradingList,
    TypeQualifyingQuestionCart,
    TypeReportSetting,
    TypeSelectTestCart,
} from "./types"

export type State = {
    assessmentDetails: TypeAssessmentDetails
    selectTestCart: TypeSelectTestCart
    customQuestionCart: TypeCustomQuestionCart
    welcomeVideoPath: String
    externalWelcomeVideoLink: String
    assessmentStatus: String
    qualifyingQuestionCart: TypeQualifyingQuestionCart
    activeAccordion: String
    assessmentConfig: assessmentConfig
    gradingItems: TypeGradingList
    reportSettings: TypeReportSetting
    questionLimit: Number
    optionLimit: Number
}

export const state: State = {
    assessmentDetails: {
        id: "",
        title: "",
        jobRoleId: "",
        otherJobRole: "",
        jobRoleName: "",
        customQuestionTimerType: "",
        customQuestionTime: 0,
        assessmentDescription: "",
    },
    welcomeVideoPath: "",
    externalWelcomeVideoLink: "",
    selectTestCart: [],
    customQuestionCart: [],
    qualifyingQuestionCart: [],
    activeAccordion: "1",
    assessmentConfig: {
        mandatoryTestLibrary: false,
        mandatoryCamMic: false,
        capture30sec: false,
        customRedirectingPage: "",
        redirectMethod: "DEFAULT_REDIRECT_PAGE",
        assessmentStatus: "",
        defaultLanguage: "en",
        skipRegistration: false,
        locationAccess: false,
        recipientList: [],
        testAdministratorList: "",
        enableGrammarly: false,
        disableFullScreen: false,
        logGenerationRequired: false,
        forceFullScreen: false,
        forceCopyPasteViolation: false,
        forceTabSwitch: false,
        forcePhotoAnalysis: false,
        sendAssessmentResultEmail: false,
        invitationEmailLinkValidity: 30,
        AssessmentDeadlineType: "NO_OF_DAYS",
        invitationEmailLinkExpiryDate: "",
        customFields: [],
        numberOfAttempts: 1,
        assessmentInstructions: "",
        enableNavigationToPreviousQuestions: true,
        enableFeedbackAfterSection: false,
        screenShare: false,
        snapshotIntervalType: "DEFAULT_SNAPSHOT_INTERVAL",
        customSnapshotInterval: 120,
        candidateAccommodation: false,
        disableMobileAndTabletDevices: false,
        enabledPracticeTest: false,
        violation: {
            tabSwitch: {
                warning: false,
                limit: 5,
            },
            fullScreenExit: {
                warning: false,
                limit: 5,
            },
            photoAnalysis: {
                warning: false,
                limit: 5,
            },
            copyPaste: {
                warning: false,
                limit: 5,
            },
            mouseOut: {
                warning: false,
                limit: 0,
            },
        },
        isHonestyAgreement: false,
        customHonestyAgreement: "",
    },
    assessmentStatus: "",
    gradingItems: [],
    reportSettings: {
        candidateInfo: false,
        expectedAnswer: false,
        performanceAnalytics: false,
        proctoringInfo: false,
        questionAsked: false,
        statusLogs: false,
    },
    questionLimit: 0,
    optionLimit: 0,
}
