import AudioQuestion from "@/assets/media/svg/audio-test-icon-cart.svg"
import CodingTest from "@/assets/media/svg/coding-icon-cart.svg"
import CognitiveTest from "@/assets/media/svg/cognitive-test-icon.svg"
import ChatIcon from "@/assets/media/svg/create-assessment-icons/chatsimulator.svg"
import DROPDOWN from "@/assets/media/svg/create-assessment-icons/dropdown-question.svg"
import FillInBlank from "@/assets/media/svg/create-assessment-icons/fill-the-blank.svg"
import NumberQues from "@/assets/media/svg/create-assessment-icons/number-question.svg"
import RANKING from "@/assets/media/svg/create-assessment-icons/ranking-question.svg"
import RATING from "@/assets/media/svg/create-assessment-icons/rating-question.svg"
import ShortAnswer from "@/assets/media/svg/create-assessment-icons/short-answer.svg"
import DateAnswer from "@/assets/media/svg/date-option-icon.svg"
import DocumentQuestion from "@/assets/media/svg/document-cart.svg"
import EngineeringSkills from "@/assets/media/svg/engineering-type-icon.svg"
import FileUpload from "@/assets/media/svg/file-upload-icon-big-cart.svg"
import Language from "@/assets/media/svg/language-type-icon.svg"
import MultiSelect from "@/assets/media/svg/multiple-select-question-cart.svg"
import WrittenAnswer from "@/assets/media/svg/open-ended-question-cart.svg"
import ProgrammingSkills from "@/assets/media/svg/programming-type-icon.svg"
import Psychometric from "@/assets/media/svg/psychometric-test-type.svg"
import QualifyingQuestion from "@/assets/media/svg/qualifier-question-cart.svg"
import qualifyingQuestion from "@/assets/media/svg/qualifier-question.svg"
import VideoAnswer from "@/assets/media/svg/record-video-question-cart.svg"
import RoleSpecificSkills from "@/assets/media/svg/role-specific-icon.svg"
import SingleSelect from "@/assets/media/svg/single-select-question-cart.svg"
import SituationalJudgement from "@/assets/media/svg/situational-judgement-icon.svg"
import Slides from "@/assets/media/svg/slides-cart.svg"
import SoftwareSkills from "@/assets/media/svg/software-skills-icon.svg"
import SpreadSheet from "@/assets/media/svg/spreadsheet-cart.svg"
import TypingTest from "@/assets/media/svg/typing-cart.svg"
import ChoiceQuestion from "@/assets/media/svg/yes-no-option-icon.svg"
import Beginner from "@/assets/media/svg/Beginner-level-badge.svg"
import Intermediate from "@/assets/media/svg/Intermediate-level-badge.svg"
import Advanced from "@/assets/media/svg/Advanced-level-badge.svg"
import Expert from "@/assets/media/svg/Expert-level-badge.svg"
import { SUPPORT_EMAIL } from "@/core/constants"
import colorLib from "@kurkle/color"
import algoliasearch from "algoliasearch/lite"
import moment from "moment/moment"
import { useRouter } from "vue-router"
import i18n from "../plugins/i18n"
import { store } from "../store"
import {
    ALGOLIA_APP_ID,
    ALGOLIA_READ_KEY,
} from "../view/pages/general/search/constants"

export default function helperFunction() {
    //convert hh:mm:ss to seconds
    const convertTimeToSeconds = (time: any) => {
        const timeArray = time.split(":")
        const hours = parseInt(timeArray[0])
        const minutes = parseInt(timeArray[1])
        const seconds = parseInt(timeArray[2])
        return hours * 3600 + minutes * 60 + seconds
    }

    const convertMinutesToSeconds = (minutes: any) => {
        return minutes * 60
    }
    const whiteLabeledPlan = [
        "white-labeled-usd-yearly",
        "white-labeled-inr-yearly",
        "white-labeled-gbp-yearly",
        "white-labeled-inr-monthly",
        "white-labeled-usd-monthly",
        "white-labeled-INR-Monthly",
        "white-labeled-gbp-monthly",
    ]
    const basicPlan: string[] = [
        "grow-inr-yearly",
        "grow-INR-Yearly",
        "grow-INR-Monthly",
        "grow-USD-Yearly",
        "grow-usd-yearly",
        "grow-USD-Monthly",
        "grow-GBP-Yearly",
        "grow-GBP-Monthly",
    ]

    //convert Seconds value to minutes and seconds in min:sec format
    const formatTime = (s: number) => {
        return (s - (s %= 60)) / 60 + (9 < s ? ":" : ":0") + s.toFixed()
    }

    //convert Seconds value to hours,minutes and seconds in 1hr:2min:3sec format
    const toHMSTime = (d: number) => {
        d = Number(d)
        const h = Math.floor(d / 3600)
        const m = Math.floor((d % 3600) / 60)
        const s = Math.floor((d % 3600) % 60)

        let hDisplay = h > 0 ? h + "h" : ""
        let mDisplay = m > 0 ? m + "m" : ""
        let sDisplay = s > 0 ? s + "s" : ""

        let timeString = ""

        if (h > 0 && m > 0 && s > 0) {
            timeString = hDisplay + " " + mDisplay + " " + sDisplay
        } else if (h > 0 && m > 0 && s <= 0) {
            timeString = hDisplay + " " + mDisplay
        } else if (h <= 0 && m > 0 && s > 0) {
            timeString = mDisplay + " " + sDisplay
        } else if (h > 0 && m <= 0 && s <= 0) {
            timeString = hDisplay
        } else if (h <= 0 && m > 0 && s <= 0) {
            timeString = mDisplay
        } else if (h <= 0 && m <= 0 && s > 0) {
            timeString = sDisplay
        }

        return timeString
    }

    const timeValueMin = (value: number) => {
        return value > 60 ? "constants_text.minutes" : "constants_text.minute"
    }

    const convertSecToMin = (value: number) => {
        if (!value) {
            return 0
        }
        const minutes = value / 60
        const formattedMinutes =
            minutes % 1 === 0 ? minutes.toFixed(0) : minutes.toFixed(2)
        return `${formattedMinutes}`
    }

    const roleNamesFormatting = (value: string) => {
        if (value === "ADMIN") {
            return "Admin"
        } else if (value === "OWNER") {
            return "Owner"
        } else if (value === "MEMBER") {
            return "Member"
        }
        return value
    }
    const initialCapitalize = (value: string) => {
        if (value) {
            return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
        } else {
            return ""
        }
    }
    const initialLowerCase = (value: string) => {
        if (value) {
            return value.toLowerCase()
        } else {
            return ""
        }
    }

    // TO_BE_EVALUATED ==> To Be Evaluated
    const snakeCaseToTitleCase = (text: string) => {
        if (!text) return ""
        return text.split("_").map(initialCapitalize).join(" ")
    }

    // To Be Evaluated ==> TO_BE_EVALUATED
    const titleCaseToSnakeCase = (text: string) => {
        if (!text) return ""
        return text
            .split(" ")
            .map((t: string) => t.toUpperCase())
            .join("_")
    }

    const convertedDate = (theDate: moment.MomentInput | null) => {
        if (!theDate) return ""
        let newDate = moment(theDate).format()
        newDate = moment(newDate).format("LL")
        return newDate
    }

    const convertedDateTime = (theDate: moment.MomentInput | null) => {
        if (!theDate) return ""
        let newDate = moment(theDate).format()
        newDate = moment(newDate).format("LLL")
        return newDate
    }

    const invitedDateTime = (theDate: moment.MomentInput | null) => {
        if (!theDate) return ""
        let apiDate = moment(theDate)
        let todayDate = moment()
        let yesterdayDate = moment().add(-1, "days")
        return moment(apiDate).format("MMM D, h:mm A")
    }
    const assessmentDeadlineDateTime = (theDate: moment.MomentInput | null) => {
        if (!theDate) return ""
        let apiDate = moment(theDate)
        return moment(apiDate).format("MMM DD, YYYY hh:mm A")
    }
    const formatStatusDateTime = (theDate: moment.MomentInput | null) => {
        if (!theDate) return ""

        const apiDate = moment(theDate)
        const todayDate = moment()
        const yesterdayDate = moment().subtract(1, "days")

        // Check if the date is from the current year
        const isCurrentYear = apiDate.isSame(todayDate, "year")

        // Format the date and time accordingly, including the year if necessary
        const dateFormat = isCurrentYear
            ? "MMM D, h:mm A"
            : "MMM D, YYYY, h:mm A"
        return moment(apiDate).format(dateFormat)
    }
    const formatDate = (theDate: string) => {
        if (!theDate) return ""
        const apiDate = moment(theDate).format("MMM DD, YYYY")
        return apiDate
    }

    const downloadURI = (uri: string) => {
        let link = document.createElement("a")
        link.href = uri
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    // set the cookie named "sessionAccessKeyCookie" as default value
    // use cookieName to set anything else in cookie(Currently I have used this function to set workspaceUrl)
    const setSessionCookie = (
        sessionKey: string,
        cookieName = "sessionAccessKeyCookie"
    ) => {
        const daysToExpire = new Date(
            Date.now() + 2147483647 * 1000
        ).toUTCString()
        const domain = location.hostname.split(".").splice(-2).join(".")
        if (window.location.href.includes("/localhost")) {
            document.cookie = `${cookieName}=${sessionKey}; path=/; expires=${daysToExpire}`
        } else if (window.location.href.includes("vercel.app")) {
            document.cookie = `${cookieName}=${sessionKey}; domain=.${window.location.host}; path=/; expires=${daysToExpire}`
        } else {
            document.cookie = `${cookieName}=${sessionKey}; domain=.${domain}; path=/; expires=${daysToExpire}`
        }
    }

    // get the value of the cookie named "sessionAccessKeyCookie" as default value
    // you can also get other cookies like (workspaceUrl)
    const getSessionCookie = (cookieName = "sessionAccessKeyCookie") => {
        let name = `${cookieName}=`
        let decodedCookie = decodeURIComponent(document.cookie)
        let ca = decodedCookie.split(";")
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i]
            while (c.charAt(0) == " ") {
                c = c.substring(1)
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length)
            }
        }
        return ""
    }

    //percent value should be negative for darker shade , positive for lighter shade
    function getShadeColor(color: string, percent: number) {
        let R = parseInt(color.substring(1, 3), 16)
        let G = parseInt(color.substring(3, 5), 16)
        let B = parseInt(color.substring(5, 7), 16)
        // @ts-ignore
        R = parseInt((R * (100 + percent)) / 100)
        // @ts-ignore
        G = parseInt((G * (100 + percent)) / 100)
        // @ts-ignore
        B = parseInt((B * (100 + percent)) / 100)

        R = R < 255 ? R : 255
        G = G < 255 ? G : 255
        B = B < 255 ? B : 255

        R = Math.round(R)
        G = Math.round(G)
        B = Math.round(B)

        let RR =
            R.toString(16).length == 1 ? "0" + R.toString(16) : R.toString(16)
        let GG =
            G.toString(16).length == 1 ? "0" + G.toString(16) : G.toString(16)
        let BB =
            B.toString(16).length == 1 ? "0" + B.toString(16) : B.toString(16)

        return "#" + RR + GG + BB
    }

    // get the value of the cookie named related to "UTM"
    const getUTMCookies = (value: any) => {
        let name = value + "="
        let decodedCookie = decodeURIComponent(document.cookie)
        let ca = decodedCookie.split(";")
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i]
            while (c.charAt(0) == " ") {
                c = c.substring(1)
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length)
            }
        }
        return ""
    }

    const showImagePreview = () => {
        let clickCount = 0
        let timer

        document.addEventListener(
            "click",
            function (e: any) {
                if (
                    e.target.tagName === "IMG" &&
                    e.target.src.indexOf("cdn.") !== -1 &&
                    e.target.src.indexOf("question") !== -1
                ) {
                    clickCount++
                    if (clickCount === 1) {
                        timer = setTimeout(function () {
                            let lightbox = new window.FsLightbox()
                            lightbox.props.sources = [e.target.src]
                            lightbox.props.types = ["image"]
                            lightbox.open()
                            clickCount = 0
                        }, 200)
                    } else {
                        clearTimeout(timer)
                        clickCount = 0
                    }
                }
            },
            false
        )

        document.addEventListener(
            "dblclick",
            function () {
                clearTimeout(timer)
                clickCount = 0
            },
            false
        )
    }

    // delete the cookie named "sessionAccessKeyCookie"
    const deleteSessionCookie = (cookieName = "sessionAccessKeyCookie") => {
        const domain = location.hostname.split(".").splice(-2).join(".")
        if (window.location.href.includes("/localhost")) {
            document.cookie = `${cookieName}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`
        } else if (window.location.href.includes("vercel.app")) {
            document.cookie = `${cookieName}=; domain=.${window.location.host}; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`
        } else {
            document.cookie = `${cookieName}=; domain=.${domain}; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`
        }
    }

    const loadScript = (
        url: string,
        async: boolean = false,
        defer: boolean = false
    ) => {
        return new Promise((resolve) => {
            let head = document.querySelectorAll("head")[0]
            let script = document.createElement("script")

            script.type = "text/javascript"
            script.async = async
            script.defer = defer
            script.addEventListener("load", () => {
                resolve(script)
            })

            script.src = url
            head.append(script)
        })
    }
    const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_READ_KEY)

    const randomStringGenerator = (len) => {
        let text = ""

        let charset = "abcdefghijklmnopqrstuvwxyz0123456789"

        for (let i = 0; i < len; i++)
            text += charset.charAt(Math.floor(Math.random() * charset.length))

        return text
    }
    const setFavicon = (faviconUrl: string) => {
        let link: any =
            document.querySelector("link[rel*='icon']") ||
            document.createElement("link")
        link.type = "image/x-icon"
        link.rel = "shortcut icon"
        link.href = faviconUrl
        document.getElementsByTagName("head")[0].appendChild(link)
    }
    const getDurationValues = (durationList: any) => {
        return durationList
            .map((item) => item.value)
            .filter((item) => item !== 1)
    }
    function getCompanyName(email) {
        const atIndex = email.indexOf("@")
        if (atIndex === -1) return null // no '@' symbol found

        const domain = email.substring(atIndex + 1) // get the domain name
        const dotIndex = domain.indexOf(".")
        if (dotIndex === -1) return null // invalid domain name

        return domain.substring(0, dotIndex)
    }
    function getCurrencyCode() {
        const dateString = new Date().toString()
        return dateString.includes("India")
            ? "INR"
            : dateString.includes("British")
            ? "GBP"
            : "USD"
    }

    function getPreviewDomain() {
        const host = window.location.host
        const domainMap = {
            "localhost:8080": "localhost:3000",
            "assessments.join-staging.com":
                "https://candidates.join-staging.com",
            "assessments.join.com": "https://candidates.join.com",
        }

        const defaultDomain = "https://candidate.testlify.com"
        const resellerDomain = store.getters.getResellerDomain

        // Determine previewDomain based on host or reseller domain
        const previewDomain =
            domainMap[host] ||
            (resellerDomain ? `https://${resellerDomain}` : defaultDomain)

        return previewDomain
    }

    const isNumberWithMaxDigit = (evt: KeyboardEvent) => {
        const keysAllowed: string[] = [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
        ]
        const keyPressed: string = evt.key

        if (!keysAllowed.includes(keyPressed)) {
            evt.preventDefault()
        }
        const maxLimit = 3
        const inputElement = evt.target as HTMLInputElement
        if (inputElement.value.length >= maxLimit) {
            evt.preventDefault()
        }
    }

    const isValidNumericInput = (evt: KeyboardEvent) => {
        const keysAllowed: string[] = [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "-",
            ".",
        ]

        const keyPressed: string = evt.key
        const inputElement = evt.target as HTMLInputElement
        const currentValue = inputElement.value

        if (!keysAllowed.includes(keyPressed)) {
            evt.preventDefault()
            return
        }

        if (
            keyPressed === "-" &&
            (currentValue.includes("-") || currentValue.length > 0)
        ) {
            evt.preventDefault()
            return
        }

        if (keyPressed === "." && currentValue.includes(".")) {
            evt.preventDefault()
            return
        }

        if (keyPressed === "." && currentValue === "-") {
            evt.preventDefault()
            return
        }
        const maxLimit = 5
        if (inputElement.value.length >= maxLimit) {
            evt.preventDefault()
        }
    }

    const isNumberAllowed = (evt: KeyboardEvent) => {
        const keysAllowed: string[] = [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            ".",
        ]
        const keyPressed: string = evt.key

        if (!keysAllowed.includes(keyPressed)) {
            evt.preventDefault()
        }
    }
    const router = useRouter()
    const updateQueryParameter = (param, value) => {
        const query = { ...router.currentRoute.value.query }
        if (value) {
            query[param] = value
        } else {
            delete query[param]
        }
        router.replace({
            query: query,
        })
    }
    function getColorByBgColor(bgColor) {
        let color =
            bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor
        let r = parseInt(color.substring(0, 2), 16) // hexToR
        let g = parseInt(color.substring(2, 4), 16) // hexToG
        let b = parseInt(color.substring(4, 6), 16) // hexToB
        return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#ffffff"
    }
    const transparentise = (value, opacity) => {
        let alpha = opacity === undefined ? 0.5 : 1 - opacity
        return colorLib(value).alpha(alpha).rgbString()
    }

    const getJwtExpiration = (jwtToken) => {
        const [, payloadBase64] = jwtToken.split(".")
        const payloadJson = atob(payloadBase64)
        const payload = JSON.parse(payloadJson)
        const expirationTime = payload.exp
        return expirationTime
    }

    function numberInRange(num, low, high) {
        if (num >= low && num <= high) {
            return true
        }
        return false
    }

    function checkResellerSupport(email: string) {
        return (
            email === "joseph.abourous@ampowered.me" ||
            email === "bharat@exceedxe.com" ||
            email === "info@balticresourcing.com" ||
            SUPPORT_EMAIL === email
        )
    }

    function getMainDomain(inputDomain) {
        // Split the input domain into parts
        let domainParts = inputDomain.split(".")

        // Check if the domain has at least two parts
        if (domainParts.length >= 2) {
            // If the last part is a common top-level domain (TLD), exclude it
            if (domainParts[domainParts.length - 1].length <= 3) {
                return domainParts[domainParts.length - 2]
            } else {
                return (
                    domainParts[domainParts.length - 2] +
                    "." +
                    domainParts[domainParts.length - 1]
                )
            }
        } else {
            // If the domain has less than two parts, return the original input
            return inputDomain
        }
    }

    // Check if question has non empty options
    const hasNonEmptyOption = (questionOptions) => {
        for (let i = 0; i < questionOptions?.length; i++) {
            if (questionOptions[i]?.description?.length > 0) {
                return true
            }
        }
        return false
    }

    //To concatenate the question options
    const concatenateQuestionOptions = (optionList) => {
        let tempStr = ""
        optionList.map(
            (data) =>
                (tempStr += data.description + data.isCorrect + data.score)
        )
        return tempStr
    }
    const concatenateQuestionOptionsFIB = (optionlist) => {
        let temp = ""
        optionlist.forEach((item) => {
            if (item.options) {
                item.options.forEach((option) => {
                    temp += option.description + option.isCorrect + option.score
                })
            }
        })
        return temp
    }
    const concatenateTestCases = (optionlist) => {
        let temp = ""
        optionlist.forEach((item) => {
            temp += item.input + item.output + item.isHidden
        })
        return temp
    }

    // Extracts the typing test duration from the given object or returns the duration from the 'duration' property.
    function typingTestDuration(val: any) {
        const item = val?.answer?.[0]
        const duration = item?.split(",")?.[3]?.split(":")?.[1]?.trim()
        return duration ?? val?.elapseDuration?.toFixed() ?? null
    }
    function translateIndustry(industry) {
        switch (industry) {
            case "Accountancy, banking and finance":
                return i18n.global.t(
                    "industry_translation.accountancy_banking_finance"
                )
            case "Agriculture":
                return i18n.global.t("industry_translation.agriculture")
            case "Applicable across all industries":
                return i18n.global.t(
                    "industry_translation.applicable_across_all_industries"
                )
            case "Construction":
                return i18n.global.t("industry_translation.construction")
            case "Corporate Services":
                return i18n.global.t("industry_translation.corporate_services")
            case "Design":
                return i18n.global.t("industry_translation.design")
            case "Education":
                return i18n.global.t("industry_translation.education")
            case "Energy & Mining":
                return i18n.global.t("industry_translation.energy_mining")
            case "Entertainment":
                return i18n.global.t("industry_translation.entertainment")
            case "Hardware & Networking":
                return i18n.global.t("industry_translation.hardware_networking")
            case "Health Care":
                return i18n.global.t("industry_translation.health_care")
            case "Legal":
                return i18n.global.t("industry_translation.legal")
            case "Manufacturing":
                return i18n.global.t("industry_translation.manufacturing")
            case "Media & Communications":
                return i18n.global.t(
                    "industry_translation.media_communications"
                )
            case "Public Administration":
                return i18n.global.t(
                    "industry_translation.public_administration"
                )
            case "Public Safety":
                return i18n.global.t("industry_translation.public_safety")
            case "Real Estate":
                return i18n.global.t("industry_translation.real_estate")
            case "Recreation, Travel & Hospitality":
                return i18n.global.t(
                    "industry_translation.recreation_travel_hospitality"
                )
            case "Sales & Retail":
                return i18n.global.t("industry_translation.sales_retail")
            case "Software & IT Services":
                return i18n.global.t(
                    "industry_translation.software_it_services"
                )
            case "Transportation & Logistics":
                return i18n.global.t(
                    "industry_translation.transportation_logistics"
                )
            case "Wellness & Fitness":
                return i18n.global.t("industry_translation.wellness_fitness")
            case "Humanitarian Sector":
                return i18n.global.t("industry_translation.humanitarian_sector")
            default:
                return industry
        }
    }

    const getTestTypeIcon = (val: string) => {
        switch (val) {
            case "Cognitive ability":
                return CognitiveTest
            case "Role specific skills":
                return RoleSpecificSkills
            case "Programming skills":
                return ProgrammingSkills
            case "Coding test":
                return CodingTest
            case "Language":
                return Language
            case "Psychometric":
                return Psychometric
            case "Engineering skills":
                return EngineeringSkills
            case "Situational judgment":
                return SituationalJudgement
            case "Situational Judgement":
                return SituationalJudgement
            case "Software skills":
                return SoftwareSkills
            case "WRITTEN_ANSWER":
                return WrittenAnswer
            case "AUDIO_ANSWER":
                return AudioQuestion
            case "VIDEO_ANSWER":
                return VideoAnswer
            case "SINGLE_SELECT":
                return SingleSelect
            case "MULTI_SELECT":
                return MultiSelect
            case "TYPING_TEST":
                return TypingTest
            case "SLIDES":
                return Slides
            case "SPREADSHEET":
                return SpreadSheet
            case "MS_EXCEL":
                return SpreadSheet
            case "DOCUMENT":
                return DocumentQuestion
            case "FILE_UPLOAD":
                return FileUpload
            case "QUALIFYING":
                return qualifyingQuestion
            case "CHOICE_ANSWER":
                return ChoiceQuestion
            case "SHORT_ANSWER":
                return ShortAnswer
            case "DATE_ANSWER":
                return DateAnswer
            case "NUMBER":
                return NumberQues
            case "QUALIFYING_QUESTION":
                return QualifyingQuestion
            case "FILL_IN_BLANK":
                return FillInBlank
            case "RANKING":
                return RANKING
            case "DROPDOWN":
                return DROPDOWN
            case "CODING_ANSWER":
                return CodingTest
            case "CHAT_SIMULATION":
                return ChatIcon
            case "RATING":
                return RATING
            default:
                return CognitiveTest
        }
    }
    // Question type list: Always add any new question type in ALPHABETICAL ORDER
    const questionTypeTranslationMapList: { [key: string]: string } = {
        AUDIO_ANSWER: "audio",
        CHAT_SIMULATION: "chat_simulation",
        CODING_ANSWER: "coding",
        DATE_ANSWER: "date_answer",
        DOCUMENT: "document",
        DROPDOWN: "dropdown",
        FILE_UPLOAD: "file_upload",
        FILL_IN_BLANK: "fill_in_blank",
        MS_EXCEL: "ms_excel",
        MULTI_SELECT: "multi_select",
        NUMBER: "number",
        WRITTEN_ANSWER:
            "open_ended" /* Added WRITTEN_ANSWER here to maintain sort order as it represents OPEN_ENDED */,
        PRACTICAL: "practical",
        RANKING: "ranking",
        RATING: "rating",
        SHORT_ANSWER: "short_answer",
        SINGLE_SELECT: "single_select",
        SLIDES: "slides",
        SPREADSHEET: "spreadsheet",
        TYPING_TEST: "typing_test",
        VIDEO_ANSWER: "video_record",
        CHOICE_ANSWER:
            "choice_answer" /* Added CHOICE_ANSWER here to maintain sort order as it represents YES/NO */,
    }

    // This code creates a list of question types with their corresponding translation keys for display purposes.
    const orderedQuestionTypeMap = () => {
        const questionTypeMap = Object.keys(questionTypeTranslationMapList).map(
            (key, index) => ({
                id: index + 1,
                value: key,
                name: i18n.global.t(
                    `constants_text.${questionTypeTranslationMapList[key]}` ||
                        "constants_text.video"
                ),
            })
        )
        return questionTypeMap
    }

    const checkLang = (language, langCode) => {
        let langObj = {
            English: "EN",
            Dutch: "NL",
            Deutsch: "DE",
            Arabic: "AR",
            Italian: "IT",
            French: "FR",
            Español: "ES",
            Japanese: "JA",
            Portuguese: "PT",
            Russian: "ru",
            Mandarin: "zh",
            Chinese: "zh",
            Korean: "ko",
            Hebrew: "he",
            Turkish: "tr",
            Vietnamese: "vi",
            Greek: "el",
        }
        const langId = langObj[language] ?? ""
        return langId.toLowerCase() !== langCode ? langId : ""
    }

    const getScoreBadge = (score) => {
        if (score > 75) return "badge-purple"
        else if (score > 50 && score <= 75) return "badge-green"
        else if (score > 25 && score <= 50) return "badge-golden"
        else if (score >= 0 && score <= 25) return "badge-red"
        else return ""
    }
    const getDifficultyLevelIcon = (difficultyLevel: string) => {
        switch (difficultyLevel) {
            case "beginner":
                return Beginner
            case "intermediate":
                return Intermediate
            case "advanced":
                return Advanced
            case "expert":
                return Expert
            case "easy":
                return Beginner
            case "challenging":
                return Expert
            default:
                return ""
        }
    }

    const isVideoAudioExpired = (completedAt) => {
        const sixMonths = 6 * 30 * 24 * 60 * 60 * 1000 // Approximation of 6 months in milliseconds
        const completedDate = new Date(completedAt).getTime()
        const currentDate = new Date().getTime()
        return currentDate - completedDate > sixMonths
    }

    return {
        numberInRange,
        updateQueryParameter,
        isNumberWithMaxDigit,
        formatTime,
        basicPlan,
        whiteLabeledPlan,
        convertSecToMin,
        initialCapitalize,
        initialLowerCase,
        snakeCaseToTitleCase,
        titleCaseToSnakeCase,
        convertedDate,
        downloadURI,
        formatDate,
        setSessionCookie,
        getSessionCookie,
        deleteSessionCookie,
        convertedDateTime,
        invitedDateTime,
        formatStatusDateTime,
        loadScript,
        getUTMCookies,
        showImagePreview,
        convertTimeToSeconds,
        toHMSTime,
        searchClient,
        randomStringGenerator,
        getCompanyName,
        getCurrencyCode,
        getShadeColor,
        setFavicon,
        getPreviewDomain,
        getJwtExpiration,
        timeValueMin,
        convertMinutesToSeconds,
        isNumberAllowed,
        getMainDomain,
        getColorByBgColor,
        transparentise,
        typingTestDuration,
        checkResellerSupport,
        translateIndustry,
        roleNamesFormatting,
        hasNonEmptyOption,
        concatenateQuestionOptions,
        concatenateQuestionOptionsFIB,
        concatenateTestCases,
        getDurationValues,
        getTestTypeIcon,
        isValidNumericInput,
        assessmentDeadlineDateTime,
        getScoreBadge,
        isVideoAudioExpired,
        questionTypeTranslationMapList,
        orderedQuestionTypeMap,
        getDifficultyLevelIcon,
        checkLang,
    }
}
